<template>
  <div class="angel-account-information-main-container">
    <div
      class='page-template page-template--- page-template-public page-template-views page-template-revslider-page-template page-template---publicviewsrevslider-page-template-php page page-id-15528 gdlr-core-body wpb-js-composer js-comp-ver-6.5.0 vc_responsive theme-light io-jasmine icos-theme-ver-1.4.8 wordpress-version-5.7.2 " data-spy="scroll" data-target="#mainnav" data-offset="80 no-touch'
    >
      <div>
        <p></p>

        <h1 class="has-text-align-center">Angel One APIBridge</h1>

        <h3 class="has-text-align-center">
          Get India’s Genuine APIBridge<sup>TM</sup> in Production Since 2018
        </h3>

        <div class="wp-block-buttons">
          <div class="wp-block-button"></div>
        </div>

        <p class="has-text-align-center has-medium-font-size">
          <strong
            ><a
              href="https://algoji.com/apibridge-angel"
              target="_blank"
              rel="noreferrer noopener"
              >Download APIBridge</a
            ></strong
          >
        </p>

        <p class="has-text-align-center"></p>
        <div id="rpt_pricr" class="rpt_plans rpt_3_plans rpt_style_basic">
          <div class="">
            <div
              class="rpt_plan  rpt_plugin_f rpt_plan_0 rpt_recommended_plan "
            >
              <div style="text-align:center;" class="rpt_title rpt_title_0">
                Learner<img
                  style="height: 30px !important; width: 30px !important; top: 14.7708px;"
                  class="rpt_recommended"
                  src="https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/img/rpt_recommended.png"
                />
              </div>
              <div class="rpt_head rpt_head_0">
                <div class="rpt_recurrence rpt_recurrence_0">Monthly</div>
                <div class="rpt_price rpt_price_0">
                  <sup class="rpt_currency"></sup>Free
                </div>
                <div class="rpt_description rpt_description_0">
                  <i
                    ><b
                      >When You Just Want To Learn How Algo Trading Works</b
                    ></i
                  >
                </div>
              </div>
              <div class="rpt_features rpt_features_0">
                <div style="color:black;" class="rpt_feature rpt_feature_0-0">
                  Paper Trading at Net Zero Cost
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-1">
                  Connect with TradingView, Excel, Amibroker, MT4 etc.
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-2">
                  Works with Free TradingView subscription
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-3">
                  Free learning - videos and tutorials
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-4">
                  Free 100+ Strategies for all platforms
                </div>
              </div>
              <a
                @click="displayEditForm(getPaperTrading)"
                style="background:#eeee22"
                class="rpt_foot rpt_foot_0"
                >Get Free</a
              >
            </div>
            <div
              class="rpt_plan  rpt_plugin_f rpt_plan_1 rpt_recommended_plan "
            >
              <div style="text-align:center;" class="rpt_title rpt_title_1">
                Explorer<img
                  style="height: 30px !important; width: 30px !important; top: 14.7708px;"
                  class="rpt_recommended"
                  src="https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/img/rpt_recommended.png"
                />
              </div>
              <div class="rpt_head rpt_head_1">
                <div class="rpt_recurrence rpt_recurrence_1">Monthly</div>
                <div class="rpt_price rpt_price_1">₹625</div>
                <div class="rpt_description rpt_description_1">
                  <b><i>50% Discount</i></b>
                </div>
                <div class="rpt_description rpt_description_1">
                  <b><i>Experience The Speed And Power Of APIBridge</i></b>
                </div>
              </div>
              <div class="rpt_features rpt_features_1">
                <div style="color:black;" class="rpt_feature rpt_feature_1-0">
                  Everything from Free plan
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_1-1">
                  Live Trading With Angel Broking
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_1-2">
                  Heroic Support
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_1-3">
                  Both Positional and Intraday Trading
                </div>
              </div>
              <a
                @click="displayEditForm(getMonthlyPlan)"
                style="background:#eeee22"
                class="rpt_foot rpt_foot_1"
                >Buy Now</a
              >
            </div>
            <div
              class="rpt_plan  rpt_plugin_f rpt_plan_2 rpt_recommended_plan "
            >
              <div style="text-align:center;" class="rpt_title rpt_title_2">
                Determined<img
                  style="height: 30px !important; width: 30px !important; top: 14.7708px;"
                  class="rpt_recommended"
                  src="https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/img/rpt_recommended.png"
                />
              </div>
              <div class="rpt_head rpt_head_2">
                <div class="rpt_recurrence rpt_recurrence_2">Annual</div>
                <div class="rpt_price rpt_price_2">
                  <sup class="rpt_currency"></sup>₹6250
                </div>
                <div class="rpt_description rpt_description_2">
                  <b><i>When You Are Determined To Pursue Algo Trading</i></b>
                </div>
              </div>
              <div class="rpt_features rpt_features_2">
                <div style="color:black;" class="rpt_feature rpt_feature_2-0">
                  Everything from Explorer Plan
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-1">
                  Free Algoji Club Membership via Slack Channel
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-2">
                  Closed, Trusted Slack Group For Resource Sharing
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-3">
                  Knowledge Resources, See Example
                  <a
                    href="https://drive.google.com/file/d/1Q3R8A-LQX980Unx75gv9qvBUsyGEli0Z/view?usp=sharing"
                    >here</a
                  >
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-4">
                  Access to APIBridge Beta Releases
                </div>
              </div>
              <a
                @click="displayEditForm(getYearlyPlan)"
                style="background:#eeee22"
                class="rpt_foot rpt_foot_2"
                >Buy Now</a
              >
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>

        <hr class="wp-block-separator" />

        <div class="wp-block-columns">
          <div class="wp-block-column">
            <figure class="wp-block-image size-large">
              <img
                loading="lazy"
                width="810"
                height="786"
                src="https://algoji.com/wp-content/uploads/2021/08/flowchart.png"
                alt=""
                class="wp-image-15627"
                srcset="
                  https://algoji.com/wp-content/uploads/2021/08/flowchart.png         810w,
                  https://algoji.com/wp-content/uploads/2021/08/flowchart-300x291.png 300w,
                  https://algoji.com/wp-content/uploads/2021/08/flowchart-768x745.png 768w
                "
                sizes="(max-width: 810px) 100vw, 810px"
              />
            </figure>
          </div>

          <div class="wp-block-column">
            <h3>
              Don’t just trade. Api-bridge your trading to maximize profits and
              minimize risks.
            </h3>

            <p>
              APIBridge enables your trading with intelligence for strategy
              management, order management, risk management and portfolio
              management. APIBridge is the only application which truly protects
              the privacy of your trading strategies. See documentation&nbsp;<a
                href="https://kb.mycoder.pro/help"
                target="_blank"
                rel="noreferrer noopener"
                >here</a
              >.
            </p>

            <p>
              &gt;&gt; Reduce your trading costs as much as 0.1% for each order
            </p>

            <p>&gt;&gt; Trade from Multiple-Strategies in Multiple-markets</p>

            <p>
              &gt;&gt; Trade via your favorite advisor, or top strategy
              platforms like TradingView, Amibroker, MetaTrader, Excel-VBA etc.
            </p>

            <p>
              &gt;&gt; Trade using charts or via screener for 100s of stocks
            </p>
          </div>
        </div>

        <hr class="wp-block-separator" />

        <div class="wp-block-columns">
          <div class="wp-block-column">
            <h2>Benefits of Trading with APIBridge</h2>

            <p>
              While conventional trading platforms work based on only buy and
              sell; APIBridge work on 4 types of Signals:<br />Long Entry (LE):
              Equivalent to fresh Buy for creating Long position<br />Long Exit
              (LX): Equivalent to Sell for reducing/squareoff Long position<br />Short
              Entry (SE): Equivalent to fresh Sell for creating Short
              position<br />Short Exit (SX): Equivalent to Buy for
              reducing/squareoff Short position<br />For placing orders,
              APIBridge requires values either from Signal, or from your
              settings.
            </p>

            <p>
              API Bridge is intelligent application and it give you full control
              to decide:
            </p>

            <p>&gt;&gt; Your choice of Scrip list</p>

            <p>&gt;&gt; Order Quantity, Order Type and Product Type</p>

            <p>&gt;&gt; Partial exit levels; both scale-in and scale-out</p>

            <p>
              &gt;&gt; Maximum profit/loss per trade, per strategy, or per day
            </p>
          </div>

          <div class="wp-block-column">
            <figure class="wp-block-image size-large">
              <img
                src="https://algoji.com/wp-content/uploads/2018/07/graph-jasmine-b.png"
                alt=""
              />
            </figure>
          </div>
        </div>

        <hr class="wp-block-separator" />

        <div class="wp-block-columns">
          <div class="wp-block-column">
            <figure class="wp-block-image size-large">
              <img
                src="https://algoji.com/wp-content/uploads/2020/03/graph-jasmine-c.png"
                alt=""
              />
            </figure>
          </div>

          <div class="wp-block-column">
            <h2>Leverage TradingView Community</h2>

            <p>API Bridge allow easy integration with TradingView.com</p>

            <p>
              Trade using 1000s of strategies reviewed and tested by TradingView
              community. See documentation&nbsp;<a
                href="https://algoji.com/apibridge-documentation/"
                >here</a
              >. You can deploy algos based on:
            </p>

            <p>
              (a) Indicators such as SuperTrend, RSI, MACD etc,<br />(b) Price
              patterns like Open Range Breakout, Highs and Lows, Trendlines
              etc.<br />(c) Multi-leg execution such as straddle, strangle or
              butterfly.<br />(d) Your custom, backtested pinescript strategies
            </p>
          </div>
        </div>

        <hr class="wp-block-separator" />

        <h3 class="has-text-align-center">
          Get India’s Genuine APIBridge<sup>TM</sup> in Production Since 2018
        </h3>

        <p class="has-text-align-center">
          <strong
            ><a
              rel="noreferrer noopener"
              href="https://algoji.com/apibridge-angel"
              target="_blank"
              >Download APIBridge</a
            ></strong
          >
        </p>

        <p class="has-text-align-center"></p>
        <div id="rpt_pricr" class="rpt_plans rpt_3_plans rpt_style_basic">
          <div class="">
            <div
              class="rpt_plan  rpt_plugin_f rpt_plan_0 rpt_recommended_plan "
            >
              <div style="text-align:center;" class="rpt_title rpt_title_0">
                Learner<img
                  style="height: 30px !important; width: 30px !important; top: 14.7708px;"
                  class="rpt_recommended"
                  src="https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/img/rpt_recommended.png"
                />
              </div>
              <div class="rpt_head rpt_head_0">
                <div class="rpt_recurrence rpt_recurrence_0">Monthly</div>
                <div class="rpt_price rpt_price_0">
                  <sup class="rpt_currency"></sup>Free
                </div>
                <div class="rpt_description rpt_description_0">
                  <i
                    ><b
                      >When You Just Want To Learn How Algo Trading Works</b
                    ></i
                  >
                </div>
              </div>
              <div class="rpt_features rpt_features_0">
                <div style="color:black;" class="rpt_feature rpt_feature_0-0">
                  Paper Trading at Net Zero Cost
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-1">
                  Connect with TradingView, Excel, Amibroker, MT4 etc.
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-2">
                  Works with Free TradingView subscription
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-3">
                  Free learning - videos and tutorials
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_0-4">
                  Free 100+ Strategies for all platforms
                </div>
              </div>
              <a
                @click="displayEditForm(getPaperTrading)"
                style="background:#eeee22"
                class="rpt_foot rpt_foot_0"
                >Get Free</a
              >
            </div>
            <div
              class="rpt_plan  rpt_plugin_f rpt_plan_1 rpt_recommended_plan "
            >
              <div style="text-align:center;" class="rpt_title rpt_title_1">
                Explorer<img
                  style="height: 30px !important; width: 30px !important; top: 14.7708px;"
                  class="rpt_recommended"
                  src="https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/img/rpt_recommended.png"
                />
              </div>
              <div class="rpt_head rpt_head_1">
                <div class="rpt_recurrence rpt_recurrence_1">Monthly</div>
                <div class="rpt_price rpt_price_1">₹625</div>
                <div class="rpt_description rpt_description_1">
                  <b><i>50% Discount</i></b>
                </div>
                <div class="rpt_description rpt_description_1">
                  <b><i>Experience The Speed And Power Of APIBridge</i></b>
                </div>
              </div>
              <div class="rpt_features rpt_features_1">
                <div style="color:black;" class="rpt_feature rpt_feature_1-0">
                  Everything from Free plan
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_1-1">
                  Live Trading With Angel Broking
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_1-2">
                  Heroic Support
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_1-3">
                  Both Positional and Intraday Trading
                </div>
              </div>
              <a
                @click="displayEditForm(getMonthlyPlan)"
                style="background:#eeee22"
                class="rpt_foot rpt_foot_1"
                >Buy Now</a
              >
            </div>
            <div
              class="rpt_plan  rpt_plugin_f rpt_plan_2 rpt_recommended_plan "
            >
              <div style="text-align:center;" class="rpt_title rpt_title_2">
                Determined<img
                  style="height: 30px !important; width: 30px !important; top: 14.7708px;"
                  class="rpt_recommended"
                  src="https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/img/rpt_recommended.png"
                />
              </div>
              <div class="rpt_head rpt_head_2">
                <div class="rpt_recurrence rpt_recurrence_2">Annual</div>
                <div class="rpt_price rpt_price_2">
                  <sup class="rpt_currency"></sup>₹6250
                </div>
                <div class="rpt_description rpt_description_2">
                  <b><i>When You Are Determined To Pursue Algo Trading</i></b>
                </div>
              </div>
              <div class="rpt_features rpt_features_2">
                <div style="color:black;" class="rpt_feature rpt_feature_2-0">
                  Everything from Explorer Plan
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-1">
                  Free Algoji Club Membership via Slack Channel
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-2">
                  Closed, Trusted Slack Group For Resource Sharing
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-3">
                  Knowledge Resources, See Example
                  <a
                    href="https://drive.google.com/file/d/1Q3R8A-LQX980Unx75gv9qvBUsyGEli0Z/view?usp=sharing"
                    >here</a
                  >
                </div>
                <div style="color:black;" class="rpt_feature rpt_feature_2-4">
                  Access to APIBridge Beta Releases
                </div>
              </div>
              <a
                @click="displayEditForm(getYearlyPlan)"
                style="background:#eeee22"
                class="rpt_foot rpt_foot_2"
                >Buy Now</a
              >
            </div>
          </div>
        </div>
        <div style="clear:both;"></div>

        <div class="wp-block-image">
          <figure class="aligncenter size-large">
            <img
              loading="lazy"
              width="1024"
              height="576"
              src="https://algoji.com/wp-content/uploads/2021/08/rough-1024x576.jpg"
              alt=""
              class="wp-image-15618"
              srcset="
                https://algoji.com/wp-content/uploads/2021/08/rough-1024x576.jpg 1024w,
                https://algoji.com/wp-content/uploads/2021/08/rough-300x169.jpg   300w,
                https://algoji.com/wp-content/uploads/2021/08/rough-768x432.jpg   768w,
                https://algoji.com/wp-content/uploads/2021/08/rough.jpg          1280w
              "
              sizes="(max-width: 1024px) 100vw, 1024px"
            />
          </figure>
        </div>

        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
import { userDetails } from "../state/userDetails.js";
import { angelFormState } from "../state/angelFormState.js";

export default {
  name: "AngelUserAccountInformation",
  data() {
    return {
      userDetails,
      angelFormState,
    };
  },
  mounted() {},
  methods: {
    displayEditForm(planSelected) {
      debugger;
      if (
        !planSelected
      ) {
        window.location.href = `https://smartapi.angelbroking.com/publisher-login?api_key=9EzIsjAR`;
        return;
      }
      this.angelFormState.data.inputFields.userDetails.newLicenseSelected = planSelected;
      this.angelFormState.data.isUserEdit = true;
      this.angelFormState.data.isUserDetailsDisplayed = false;
    },
  },
  computed: {
    getMonthlyPlan() {
      if (this.userDetails.data.licenceArr) {
        return this.userDetails.data.licenceArr.filter(
          (plan) => plan.number_of_license == 1 && plan.module_type == 2
        )[0];
      }
      return false;
    },
    getPaperTrading() {
      if (this.userDetails.data.licenceArr) {
        return this.userDetails.data.licenceArr.filter(
          (plan) => plan.amount == 0 && plan.module_type == 1
        )[0];
      }
      return false;
    },
    getYearlyPlan() {
      if (this.userDetails.data.licenceArr) {
        return this.userDetails.data.licenceArr.filter(
          (plan) => plan.number_of_license == 12 && plan.module_type == 2
        )[0];
      }
      return false;
    },
  },
};
</script>

<style scoped>
body , html{
  background-color: #fff !important ;
}

/*! CSS Used from: https://algoji.com/wp-includes/css/dist/block-library/style.min.css?ver=5.7.2 ; media=all */
@media all {
  .wp-block-button__link {
    color: #fff;
    background-color: #32373c;
    border: none;
    border-radius: 1.55em;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-size: 1.125em;
    padding: 0.667em 1.333em;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word;
  }
  .wp-block-button__link:active,
  .wp-block-button__link:focus,
  .wp-block-button__link:hover,
  .wp-block-button__link:visited {
    color: #fff;
  }
  .wp-block-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .wp-block-buttons > .wp-block-button {
    display: inline-block;
    margin-left: 0;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
  }
  .wp-block-buttons > .wp-block-button:last-child {
    margin-right: 0;
  }
  .wp-block-columns {
    display: flex;
    margin-bottom: 1.75em;
    flex-wrap: wrap;
  }
  @media (min-width: 782px) {
    .wp-block-columns {
      flex-wrap: nowrap;
    }
  }
  .wp-block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  @media (max-width: 599px) {
    .wp-block-column {
      flex-basis: 100% !important;
    }
  }
  @media (min-width: 600px) and (max-width: 781px) {
    .wp-block-column:not(:only-child) {
      flex-basis: calc(50% - 1em) !important;
      flex-grow: 0;
    }
    .wp-block-column:nth-child(2n) {
      margin-left: 2em;
    }
  }
  @media (min-width: 782px) {
    .wp-block-column {
      flex-basis: 0;
      flex-grow: 1;
    }
    .wp-block-column:not(:first-child) {
      margin-left: 2em;
    }
  }
  .wp-block-image {
    margin-bottom: 1em;
  }
  .wp-block-image img {
    max-width: 100%;
  }
  .wp-block-image:not(.is-style-rounded) img {
    border-radius: inherit;
  }
  .wp-block-image .aligncenter {
    display: table;
  }
  .wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto;
  }
  .has-medium-font-size {
    font-size: 1.25em;
  }
  .has-text-align-center {
    text-align: center;
  }
  .aligncenter {
    clear: both;
  }
}
/*! CSS Used from: https://algoji.com/wp-content/themes/icos/assets/css/vendor.bundle.css?ver=5.7.2 ; media=all */
@media all {
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }
  figure {
    display: block;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  b,
  strong {
    font-weight: bolder;
  }
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus,
  a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  figure {
    margin: 0 0 1rem;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  h1,
  h2,
  h3 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  @media print {
    *,
    ::after,
    ::before {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    img {
      page-break-inside: avoid;
    }
    h2,
    h3,
    p {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
  }
}
/*! CSS Used from: https://algoji.com/wp-content/themes/icos/style.css?ver=5.7.2 ; media=all */
@media all {
  .aligncenter {
    margin-left: auto;
    margin-right: auto;
    display: block;
    clear: both;
  }
  img {
    max-width: 100%;
  }
  h1,
  h2,
  h3,
  p {
    margin: 0 0 18px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  h1,
  h2,
  h3 {
    line-height: 1.33;
    font-weight: 600;
    color: #373e45;
  }
  .theme-light h1,
  .theme-light h2,
  .theme-light h3 {
    line-height: 1.33;
    font-weight: 500;
    color: #28384c;
  }
  h1 {
    font-size: 2.93em;
  }
  h2 {
    font-size: 2.3em;
  }
  h3 {
    font-size: 1.87em;
  }
  p {
    font-size: 1em;
  }
  p + h1 {
    margin-top: 35px;
  }
  b,
  strong {
    font-weight: 600;
  }
  a {
    outline: 0;
    transition: all 0.5s;
    color: #41faa4;
  }
  a:link,
  a:visited {
    text-decoration: none;
  }
  a:hover,
  a:focus,
  a:active {
    outline: 0;
    color: #16a3fe;
  }
  h1,
  h2,
  h3 {
    color: #fff;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    h2 {
      font-size: 2em;
    }
  }
  @media only screen and (max-width: 991px) {
    h2 {
      font-size: 2em;
    }
  }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 2.1em;
    }
  }
  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.4em;
    }
    h3 {
      font-size: 1.3em;
    }
  }
  img {
    outline: 0;
    border: 0 none;
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }
  hr {
    margin: 45px 0;
  }
  @media only screen and (max-width: 991px) {
    hr {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
/*! CSS Used from: https://algoji.com/wp-content/themes/icos/assets/css/jasmine.css?ver=5.7.2 ; media=all */
@media all {
  .io-jasmine h1,
  .io-jasmine h2,
  .io-jasmine h3 {
    color: #223b55;
  }
  .io-jasmine p {
    color: #4c5a77;
  }
  @media only screen and (max-width: 767px) {
    .io-jasmine img {
      width: 100%;
      max-width: 360px;
    }
  }
  @media only screen and (max-width: 480px) {
    .io-jasmine h1 {
      font-size: 1.2em;
    }
  }
}
/*! CSS Used from: https://algoji.com/wp-content/themes/icos/assets/css/theme/theme-jasmine.css?ver=5.7.2 ; media=all */
@media all {
  a {
    color: #16a1ff;
  }
  a:hover,
  a:focus,
  a:active {
    color: #7a0fff;
  }
}
/*! CSS Used from: https://algoji.com/wp-content/plugins/dk-pricr-responsive-pricing-table/inc/css/rpt_style.min.css?ver=5.1.2 ; media=all */
@media all {
  .rpt_3_plans .rpt_plan {
    width: 32%;
    float: left;
  }
  .rpt_3_plans .rpt_plan_0 {
    margin-right: 1.9%;
  }
  .rpt_3_plans .rpt_plan_2 {
    margin-left: 1.9%;
  }
  .rpt_style_basic .rpt_plan .rpt_title img {
    margin-right: 10px;
    position: relative;
    vertical-align: middle;
  }
  .rpt_style_basic .rpt_plan .rpt_title {
    margin-bottom: -3px !important;
    border-bottom: #111 solid 1px;
    background: #222;
    padding: 14px 18px;
    font-size: 26px;
    color: white;
    line-height: 35px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .rpt_style_basic .rpt_plan .rpt_head {
    border-top: #222 solid 2px;
    background: rgb(34, 34, 34);
    background: linear-gradient(
      0deg,
      rgba(34, 34, 34, 1) 0%,
      rgba(51, 51, 51, 1) 100%
    );
  }
  .rpt_plan .rpt_head .rpt_price .rpt_currency {
    position: relative;
    top: 3px !important;
    left: -6px !important;
    font-size: 0.5em !important;
    vertical-align: super !important;
  }
  .rpt_style_basic .rpt_plan .rpt_head .rpt_price {
    padding: 6px 10px;
    font-size: 77px;
    line-height: 1em;
    font-weight: 300;
    text-align: center;
    color: white;
  }
  .rpt_style_basic .rpt_plan .rpt_head .rpt_recurrence {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    padding: 20px 40px 0;
    color: lightgrey;
    text-align: center;
  }
  .rpt_style_basic .rpt_plan .rpt_head .rpt_description {
    font-size: 14px;
    line-height: 20px;
    padding: 0 30px 20px;
    font-weight: 300;
    text-align: center;
    color: #999;
  }
  .rpt_style_basic .rpt_plan .rpt_features {
    padding: 10px 20px;
    background: whitesmoke;
    text-align: center;
  }
  .rpt_style_basic .rpt_plan .rpt_features .rpt_feature {
    font-size: 15px;
    line-height: 1.6em;
    padding: 5px 16px;
    border-bottom: 1px dotted lightgrey;
  }
  .rpt_style_basic .rpt_plan .rpt_features .rpt_feature:last-child {
    border-bottom: none;
  }
  .rpt_style_basic .rpt_plan .rpt_foot {
    display: block;
    padding: 15px 25px;
    border-top: whitesmoke solid 2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    color: white;
    font-size: 20px;
    text-decoration: none;
    font-weight: 300;
  }
  .rpt_style_basic .rpt_plan .rpt_foot:hover {
    opacity: 0.9;
  }
  .rpt_recommended_plan {
    transform: scale(1.05);
  }
  .rpt_recommended_plan img.rpt_recommended {
    position: absolute !important;
    right: 10px !important;
  }
  .rpt_style_basic .rpt_plan a {
    border-bottom: none;
  }
  .rpt_style_basic .rpt_plan img {
    display: inline;
    margin: 0 10px 0 0;
    padding: 0;
    border: none;
  }
  @media only screen and (max-width: 640px) {
    .rpt_plans .rpt_plan {
      width: 96%;
    }
    .rpt_plans .rpt_plan {
      margin: 10px 2% 20px !important;
    }
    .rpt_style_basic .rpt_plan .rpt_foot {
      padding: 10px 40px;
      text-align: center;
      font-size: 20px !important;
    }
    .rpt_style_basic .rpt_plan .rpt_head .rpt_price {
      font-size: 74px !important;
      line-height: 100px !important;
    }
    .rpt_style_basic .rpt_plan .rpt_title {
      position: relative;
      font-size: 26px !important;
      line-height: 35px;
    }
    .rpt_style_basic .rpt_plan .rpt_head .rpt_description {
      font-size: 15px !important;
      line-height: 26px !important;
      padding: 0 40px 20px;
    }
    .rpt_style_basic .rpt_plan .rpt_features .rpt_feature {
      padding: 5px 16px;
      font-size: 14px !important;
    }
  }
  .rpt_plugin_f .rpt_title,
  .rpt_plugin_f .rpt_head .rpt_recurrence,
  .rpt_plugin_f .rpt_head .rpt_price,
  .rpt_plugin_f .rpt_head .rpt_price .rpt_currency,
  .rpt_plugin_f .rpt_head .rpt_description,
  .rpt_plugin_f .rpt_features .rpt_feature,
  .rpt_plugin_f .rpt_features .rpt_feature a,
  .rpt_plugin_f .rpt_foot {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
    font-weight: 400 !important;
  }
}
/*! CSS Used from: Embedded */
@media only screen and (max-width: 480px) {
  .io-jasmine h1 {
    font-size: 2.2em;
  }
}
@media only screen and (max-width: 480px) {
  h3 {
    font-size: 1.8em;
  }
}
h1 {
  font-size: 47px;
}
.rpt_style_basic .rpt_plan .rpt_head .rpt_description {
  font-size: 15px;
  line-height: 30px;
  padding: 30px 30px 11px;
  font-weight: 300;
  text-align: center;
  color: #999;
}
.rpt_style_basic .rpt_plan .rpt_title {
  margin-bottom: -3px !important;
  border-bottom: #111 solid 1px;
  background: #ffffff;
  padding: 14px 18px;
  font-size: 26px;
  color: black;
  line-height: 35px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: bold;
}
.rpt_style_basic .rpt_plan .rpt_head {
  border-top: #222 solid 2px;
  background: rgb(34, 34, 34);
  background: linear-gradient(0deg, rgb(255 255 255) 0%, rgb(255 255 255) 100%);
}
.rpt_style_basic .rpt_plan .rpt_head .rpt_recurrence {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  padding: 20px 40px 0;
  color: black;
  text-align: center;
}
.rpt_style_basic .rpt_plan .rpt_head .rpt_price {
  padding: 6px 10px;
  font-size: 77px;
  line-height: 1em;
  font-weight: 300;
  text-align: center;
  color: #040404;
}
.rpt_style_basic .rpt_plan .rpt_head .rpt_description {
  font-size: 15px;
  line-height: 30px;
  padding: 30px 30px 11px;
  font-weight: 300;
  text-align: center;
  color: #000;
}
.rpt_style_basic .rpt_plan .rpt_head {
  border-top: #fff solid 2px;
  background: rgb(34, 34, 34);
  background: linear-gradient(0deg, rgb(255 255 255) 0%, rgb(255 255 255) 100%);
}
.rpt_3_plans .rpt_plan_2 {
  margin-left: 1.9%;
  padding: 34px 20px 26px;
  -webkit-box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
}
.rpt_3_plans .rpt_plan {
  width: 32%;
  float: left;
  padding: 34px 20px 26px;
}
.rpt_style_basic .rpt_plan .rpt_head .rpt_description {
  font-size: 15px;
  line-height: 30px;
  padding: 34px 31px 11px;
  font-weight: 300;
  text-align: center;
  color: #000;
}
.rpt_3_plans .rpt_plan {
  width: 32%;
  float: left;
  padding: 37px 27px 26px;
}
.rpt_style_basic .rpt_plan .rpt_foot {
  display: block;
  padding: 15px 25px;
  border-top: whitesmoke solid 2px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  color: #0073aa;
  font-size: 15px;
  text-decoration: none;
  font-weight: 300;
  font-weight: bold !important;
}
@media only screen and (max-width: 600px) {
  .rpt_3_plans .rpt_plan {
    width: 100%;
    float: left;
    padding: 37px 27px 26px;
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBBc4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu72xKOzY.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu5mxKOzY.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu7mxKOzY.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4WxKOzY.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu7WxKOzY.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu7GxKOzY.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hmIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hvIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hnIqOjjg.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hoIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hkIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hlIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWyV9hrIqM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0Udc1UAw.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0ddc1UAw.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0Vdc1UAw.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0adc1UAw.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0Wdc1UAw.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0Xdc1UAw.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem6YaGs126MiZpBA-UFUK0Zdc0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhmIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhvIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhnIqOjjg.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhoIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhkIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhlIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKXGUdhrIqM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhmIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhvIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhnIqOjjg.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhoIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhkIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhlIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKWiUNhrIqM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hmIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hvIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hnIqOjjg.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hoIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hkIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hlIqOjjg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/memnYaGs126MiZpBA-UFUKW-U9hrIqM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OX-hpOqc.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OVuhpOqc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OXuhpOqc.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OUehpOqc.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OXehpOqc.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OXOhpOqc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN_r8OUuhp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFWJ0bbck.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFUZ0bbck.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFWZ0bbck.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFVp0bbck.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFWp0bbck.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFW50bbck.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem8YaGs126MiZpBA-UFVZ0b.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UNirkOUuhp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOX-hpOqc.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOVuhpOqc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOXuhpOqc.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOUehpOqc.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOXehpOqc.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOXOhpOqc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOX-hpOqc.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOVuhpOqc.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOXuhpOqc.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOUehpOqc.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOXehpOqc.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOXOhpOqc.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url(https://fonts.gstatic.com/s/opensans/v22/mem5YaGs126MiZpBA-UN8rsOUuhp.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
</style>
